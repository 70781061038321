import {Component, inject, input, InputSignal} from '@angular/core';
import {BlockUiService} from "../../core/services/block-ui.service";

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [],
  template: `
    @if(blockUIService.loading() || loading()){
      <div style="z-index: 999" class="fixed inset-0 bg-gray-950 bg-opacity-70 flex justify-center items-center">
        <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    }
  `,
  styles: ``
})
export class LoadingComponent {
  protected blockUIService: BlockUiService = inject(BlockUiService);
  public loading:InputSignal<boolean> = input<boolean>(false);
}
